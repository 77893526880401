import React, { useState } from 'react';
import { Link } from "react-router-dom";

export const DesktopNavbar = ({ links, bgTheme }) => {
    const [current, setCurrent] = useState("");

    return (
        <nav className={`navbar ${bgTheme}`}>
            <Link to="/" style={{cursor: "pointer"}}>
                <img className="navbar-logo" alt="NUAT logo"
                    src="/nuat-logo.png" onClick={() => setCurrent("")}
                />
            </Link>
            <div className="navbar-content">
                {links.map((item, i) => (
                    <React.Fragment key={i}>
                        {item.link === "/about" && <div className="vbar" />}
                        <Link to={item.link} className={item.name === current ? "active" : ""}
                            onClick={() => setCurrent(item.name)}>
                            {item.name}
                        </Link>
                        {/* {item.link === "/about" && <div className="vbar" />} */}
                    </React.Fragment>
                ))}
                {/* <a style={{color: 'black'}}><i className="fa fa-user-circle"></i></a> */}
            </div>
        </nav>
    );
};