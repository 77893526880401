import React, { useState, useEffect, useReducer } from 'react';
import SearchBar from '../components/SearchBar';
// import SearchLocationBar from '../components/SearchLocationBar';
import * as cards from "../components/cards";
// import locationIcon from "../icons/svg/location.svg";
import { useBackground } from '../PageContext';
import { usePrefetch } from '../utils';


const filterData = (data, action) => {
    data = action.data || data;

    const searchedText = action.searchedText || '';
    if (searchedText === '') return data;

    return data.filter(item => {
        let isValid = false;
        Object.values(item).forEach(value => {
            if (value.includes(searchedText)) isValid = true;
        });
        return isValid;
    });
};

export const Sectors = () => {
    useBackground("white");
    const [displayProducts, setDisplayProducts] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    const [sectors,] = usePrefetch("/api/sectors");
    const [products,] = usePrefetch("/api/products");

    const [data, updateData] = useReducer(filterData, []);
    const [fadeIn, setFadeIn] = useState({});

    const onSwitch = (e) => {
        setFadeIn({ animation: "fadeIn 1.3s ease-in-out" });
        setDisplayProducts(e.target.checked);
        setTimeout(() => setFadeIn({}), 1000);
    };

    useEffect(() => {
        updateData({ data: displayProducts ? products : sectors });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayProducts]);

    return (
        <div>
            <div className="bars-fixed">
                <div className="flex bars-left">
                    <div className="bar-wrapper">
                        <div className={displayProducts ? "text-muted" : "text-active"}>
                            Filières
                        </div>
                        <label className="switch">
                            <input type="checkbox" onChange={onSwitch} />
                            <span className="slider"></span>
                        </label>
                        <div className={"last" + (!displayProducts ? "text-muted" : "text-active")}>
                            Produits
                        </div>
                    </div>
                    {/* <SearchBar
                        searchedText={searchedText}
                        setSearchedText={setSearchedText}
                        updateData={updateData}
                    /> */}
                </div>
                <div className="flex bars-right">
                    {/* <div className="bar-wrapper">
                        <img className="last" src={locationIcon} alt="Location Icon" />
                    </div>
                    <SearchLocationBar
                        searchedText={searchedText}
                        setSearchedText={setSearchedText}
                        updateData={updateData}
                    /> */}
                    <SearchBar
                        searchedText={searchedText}
                        setSearchedText={setSearchedText}
                        updateData={updateData}
                    />
                </div>
            </div>

            <cards.CardWrapper data={data} showProduct={displayProducts} style={fadeIn} />
        </div>
    );
};