import React, { useRef, useState } from 'react';

const JobSlider = (props) => {
    const cardRef = useRef(null);

    const [scrollPerClick, setScrollPerClick] = useState(300);

    const handle = () => {
        const cardWidth = cardRef.current.firstChild.offsetWidth;
        if (cardWidth !== 0) {
            let n = Math.floor(cardRef.current.offsetWidth / cardWidth);
            setScrollPerClick(n * cardWidth);
        } else setScrollPerClick(cardRef.current.offsetWidth);

    };

    const sliderScrollLeft = () => {
        cardRef.current.scrollTo({
            top: 0,
            left: cardRef.current.scrollLeft - scrollPerClick,
            behavior: "smooth",
        });
    };

    const sliderScrollRight = () => {
        if (
            cardRef.current.scrollLeft <=
            cardRef.current.scrollWidth - cardRef.current.clientWidth
        ) {
            cardRef.current.scrollTo({
                top: 0,
                left: cardRef.current.scrollLeft + scrollPerClick,
                behavior: "smooth",
            });
        };
    };

    return (
        <div className="jobslider" onMouseOver={handle}>
            <div className="chevron">
                <i className="fa fa-chevron-left" onClick={sliderScrollLeft} />
            </div>
            <div ref={cardRef} className="jobs-list">
                {props.data.map(job => (
                    <div
                        key={job.id}
                        className="job-card"
                        onClick={() => props.updateCurrent(job.id)}
                    >
                        <h2>{job.name}</h2>
                        <em>En savoir plus</em>
                    </div>
                ))}
            </div>
            <div className="chevron">
                <i className="fa fa-chevron-right" onClick={sliderScrollRight} />
            </div>
        </div>
    );
};

export default JobSlider;