import React from 'react';

export const AmbassadorWrapper = ({ data }) => {
    const getImagePath = (img) => `/ressources/avatar/${img}`;

    return (
        <React.Fragment>
            {data.map((ambassador, i) => (
                <div key={i} className={"ambassador-wrapper " + (i % 2 === 0 ? "_left" : "right")}>
                    <div className="ambassador-tape">
                        <div className="ambassador-desc">
                            {ambassador.description}
                        </div>
                        {/* <button className="learn-more-btn">
                            Contacter {ambassador.name}
                        </button> */}
                    </div>
                    <div className="ambassador-card">
                        <img src={getImagePath(ambassador.image)} alt={ambassador.name} />
                        <div className="card-info">
                            <h2>{ambassador.name} {ambassador.surname}</h2>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};
