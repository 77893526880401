import { useState, useEffect } from 'react';

// boolean to check the environnement mode: DEV or PROD ?
export const development =
	!process.env.NODE_ENV
	|| process.env.NODE_ENV === 'development';

export const DEVICE_ID_KEY = "NUAT_DEVICE_ID";

// Override the default console.log function
export const log = (...data) => {
	if (development) {
		console.log('[NUAT]', ...data);
	};
};

// Python range function
export const range = (start, stop) => {
	if (!Number.isInteger(stop) || !Number.isInteger(start))
		throw new Error("Expected integer");
	return [...Array(stop - start).keys()].map(i => i + start)
};

export const googleKey = {
	clientId: process.env.GOOGLE_CLIENT_ID,
	ApiKey: process.env.GOOGLE_API_KEY
};

// Hook to get dynamically the dimensions of the window
export const useWindowDimensions = () => {
	const hasWindow = typeof window !== 'undefined';
	// eslint-disable-next-line
	const getDimensions = () => ({
		width: hasWindow ? window.innerWidth : null,
		height: hasWindow ? window.innerHeight : null
	});
	const [dimensions, setDimensions] = useState(getDimensions());

	useEffect(() => {
		if (hasWindow) {
			const handleResize = () => {
				setDimensions(getDimensions());
			};
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [hasWindow, getDimensions]);

	return dimensions;
};

// A hook which will halt the rendering of a component until the async function 'cb' has resolved.
// The nearest <Suspense> element will use its fallback until this happens.
// Afterwards, rendering will resume and the hook will return the value of the callback.
// Warning: The 'id' parameter identifies the action being taken by 'cb'.
// Only one callback execution will occur under each id, and the result is cached based on it.
// Unless 'noReload' is true, the cache will be cleared and another callback execution can occur
// once the component unmounts,
const suspenseCache = {
	loaded: {},
	data: {},
	promises: {},
};

export const useSuspense = (cb, id, noReload) => {
	useEffect(() => { // Refetch data when component is reloaded
		return noReload ? () => null : () => {
			log('Uncaching ' + id);
			suspenseCache.loaded[id] = false;
			delete suspenseCache.data[id];
			delete suspenseCache.promises[id];
		};
	}, [id, noReload]);
	// }
	if (suspenseCache.loaded[id]) {
		return suspenseCache.data[id];
	} else {
		if (!suspenseCache.promises[id]) {
			log('Fetching', id);
			suspenseCache.promises[id] = cb()
				.then(data => {
					log('Resolved', id);
					suspenseCache.loaded[id] = true;
					suspenseCache.data[id] = data;
					delete suspenseCache.promises[id];
				});
		}
		throw suspenseCache.promises[id];
	}
};

const fetchTimeout = (url, options) => {
	const timeout = (options && options.timeout) || 10000;
	const controller = new AbortController();

	return new Promise((resolve, reject) => {
		const promise = fetch(url, {
			signal: controller.signal,
			...options
		});
		const timeoutId = setTimeout(() => {
			controller.abort();
			reject(new Error(
				`Fetch of ${url} timed out after ${timeout} ms`
			));
		}, timeout);
		promise.then((res) => {
			clearTimeout(timeoutId); // prevent timeoutId to execute
			resolve(res);
		}).catch((err) => {
			clearTimeout(timeoutId);
			reject(err);
		});
	});
};

export const niceFetch = async (url, clean) => {
	try {
		const res = await fetchTimeout(url);
		if (!res.ok)
			throw new Error(res.status + ' Error: ' + res.statusText);
		const response = await res.json();
		return clean ? clean(response) : response;
	} catch (err) {
		console.error(err);
		throw new Error('Could not GET from: ' + url);
	}
};

export const niceSend = async (url, method, data, isFormData = false) => {
	const withData = data !== undefined;
	const headers =
		(withData && !isFormData)
			? { 'Content-Type': 'application/json' }
			: {};
	if (data && data.hasOwnProperty('token')) {
		headers["Authorization"] = 'Bearer ' + data.token;
		delete data.token;
	};

	try {
		const res = await fetchTimeout(url, {
			method,
			headers,
			body:
				withData
					? (isFormData ? data : JSON.stringify(data))
					: undefined
		});
		const response = await res.json();
		if (!res.ok && !response.hasOwnProperty('error')) {
			throw new Error(
				res.status + ' Error: ' + res.statusText
			);
		}
		return response;
	} catch (err) {
		console.error(err);
		throw new Error('Could not ' + method + ' to ' + url);
	}
};

// A hook which will halt the rendering of the component until the data at 'url' has been fetched.
// The nearest <Suspense> element will use its fallback until this happens.
// The data received populates a new state property, as returned by 'useState'.
// The hook returns an array containing this state, as well as a callback to re-fetch its value.
// Warning: the URL should be a static one here!
export const usePrefetch = (url, clean) => {
	const initialData = useSuspense(() => niceFetch(url, clean), url);
	const [data, setData] = useState(initialData);
	const updateData = async () => {
		const newData = await niceFetch(url, clean);
		setData(newData);
		return newData;
	};
	return [data, updateData];
};

// Similar to usePrefetch, but for cases where the URL could potentially change during the lifetime
// of the object, and where this should trigger a <Suspense> period.
export const useDynamicPrefetch = (url, dependencies, clean) => {
	const initialData = useSuspense(() => niceFetch(url, clean), url);
	const [data, setData] = useState(initialData);
	const updateData = async () => {
		const newData = await niceFetch(url, clean);
		setData(newData);
		return newData;
	};
	useEffect(() => {
		updateData();
		// eslint-disable-next-line
	}, dependencies);
	return [data, updateData];
};

// Paris geolocation
export const PARIS = { lat: 48.357, lng: 2.37 };

// user location
const location = sessionStorage.location;
const { lat, lng } = location ? JSON.parse(location) : PARIS;
export const position = { lat, lng };

// Hook to fetch dynamically the address to use. Default to user location
// but user can change this manually ! 
export const useDefaultAddress = () => {
	const [locationData, setLocationData] = useState({});
	useEffect(() => {
		const fetchAsync = async () => {
			try {
				const data = await niceFetch(
					`/api/location/?lng=${lng}&lat=${lat}`
				);
				setLocationData(data);
			} catch (err) {
				log(
					"Error while getting location info (city, postcode...): ",
					err
				)
			}
		};
		fetchAsync();
	}, []);

	return locationData;
};

const PASSWORD_LENGTH = 8;

// Helper function to validate form data before sending it to server
export const validate = (data, setError, type = "register") => {
	setError({});
	let errors = {};
	let isValid = true;

	const basic = () => {
		let list;
		switch (type) {
			case "reset":
				list = ["password", "passwordBis"];
				break;
			case "contactus":
				list = ["name", "surname", "email", "message"];
				break;
			default:
				list = [
					"name",
					"surname",
					"email",
					"emailBis",
					"password",
					"passwordBis"
				];
		}
		list.forEach(key => {
			if (!data[key] || data[key] === '') {
				isValid = false;
				errors[key] = "Veuillez remplir ce champ";
			};
		});
	};
	basic();

	if (data.email !== undefined) {
		if (type === "register" && data.email !== data.emailBis) {
			isValid = false;
			errors.emailBis = "Emails non identiques."
		};
		// eslint-disable-next-line
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(data.email.toLowerCase())) {
			isValid = false;
			errors.email = "Adresse email invalide";
		};
	};

	if (type !== "contactus" && data.password !== undefined) {
		if (data.password !== data.passwordBis) {
			isValid = false;
			errors.passwordBis = "Mots de passe non identiques";
		};
		if (data.password.length < PASSWORD_LENGTH) {
			isValid = false;
			errors.password =
				`Le mot de passe doit contenir au moins ${PASSWORD_LENGTH} caractères`;
		};
	};

	setError({ ...errors });
	return isValid;
};
