import React from 'react';
import { useDynamicPrefetch } from '../../utils';
import Video from '../Video';
import { ProductCard } from './ProductCard';

export const JobCard = () => {
    return (
        <div>
        </div>
    );
};

export const JobVideoPopup = (props) => {
    const [data,] = useDynamicPrefetch(`/api/jobs/${props.id}`, [props.id], (data) => {
        const { basicData } = data;
        return {
            ...basicData,
            sectors: data.sectors,
            ambassadors: data.ambassadors
        };
    });

    return (
        <div className="overlay" style={{ display: props.show ? "block" : "none" }}>
            <div className="popup">
                <div className="product-popup">
                    {/* TODO: Chqnge this component later */}
                    <ProductCard name={data?.name} image="jobs.jpg" description="" freeze />

                    <button className="close-btn" onClick={props.close}>
                        <i className="fa fa-close" />
                    </button>

                    <div className="product-video-card">
                        <Video url={data?.video ?? "https://youtube.com"} />

                        <div className="card-description">
                            {(data ?? {}).description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};