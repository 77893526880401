import React, { useEffect, useState } from 'react';

const Video = (props) => {
    const [id, setId] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        const getUrl = () => {
            if (props.url.includes("embed")) {
                return props.url;
            }
            const url = new URL(props.url);
            const params = new URLSearchParams(url.search);
            const id = params.get("v");
            if (id) {
                setId(id);
            }
            return url.origin + `/embed/${id}`;
        };

        setUrl(getUrl());
    }, [props.url]);

    useEffect(() => {
        if (props.play === false) {
            const iframe = document.getElementById(`video:${id}`);
            iframe
                .contentWindow
                .postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }
        // eslint-disable-next-line
    }, [props.play]);

    return (
        <div className="video">
            <iframe
                src={url}
                id={`video:${id}`}
                title="Video"
                allowFullScreen={true}
                frameBorder="0"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen" />
        </div>
    )
};

export default Video;