import React from 'react';

const SearchBar = (props) => {
    const { searchedText, setSearchedText } = props;

    return (
        // Remove large size after
        <div className="bar-wrapper">
            <div className="flex">
                <i className="fa fa-search" />
            </div>
            <div className="vbar" />
            <input
                className="last"
                style={{ width: "350px" }}
                value={searchedText}
                placeholder="Rechercher"
                onChange={(e) => setSearchedText(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;