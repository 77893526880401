import React, { useState } from 'react';

import Video from "../Video";
import { useNavigate } from 'react-router-dom';
import { usePrefetch } from "../../utils";

export const ProductCard = (props) => {
    const [hide, setHide] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const getImagePath = () => `/ressources/products/${props.image}`;

    const onMouseHover = (hovered) => {
        if (props.freeze) { setHide(false); return; }
        if (hovered) {
            setTimeoutId(setTimeout(
                () => setHide(hovered),
                100
            ));
        }
        else {
            if (timeoutId) clearTimeout(timeoutId);
            setHide(false);
        }
    };

    return (
        <div className="product-card"
            style={{ cursor: props.freeze ? "auto" : "pointer" }}
            onClick={() => props.moreInfo(props.id)}
            onMouseEnter={() => onMouseHover(true)}
            onMouseLeave={() => onMouseHover(false)}
        >
            <div className='product-image'>
                <img src={getImagePath()} alt={props.name} />
            </div>
            <div className="vbar" />
            <div className="card-body">
                <div className="card-info">
                    <h2>{props.name}</h2>
                    {hide && <div className="left">
                        <i className="fa fa-chevron-right" />
                    </div>}
                </div>
                <div className={"card-description" + (props.freeze ? " freeze" : "")}>
                    {props.hide
                        ? <em className='more-info-em'>En savoir plus</em>
                        : props.description
                    }
                </div>
            </div>
        </div>
    );
};


export const ProductVideoPopup = (props) => {
    const [sectorId, setSectorId] = useState(null);
    const [play, setPlay] = useState(true);
    
    const id = (props.product ?? {}).id ?? 0;
    usePrefetch(
        `/api/products/${id}`,
        (data) => {
            const ids = (data.sectors || []).map(sector => sector.id);
            if (ids.length) {
                setSectorId(ids[0]);
            }
            return data;
        }
    );

    const close = () => {
        setPlay(false);
        props.close();
    };

    const navigate = useNavigate();
    const goToSector = () => {
        if (!sectorId) {
            // TODO
            console.log("This should trigger an error");
            return;
        }
        navigate(`/sectors/${sectorId}`);
    };

    return (
        <div className="overlay" style={{ display: props.show ? "block" : "none" }}>
            <div className="popup">
                <div className="product-popup">
                    <ProductCard {...props.product} description="" freeze />

                    <button className="close-btn" onClick={close}>
                        <i className="fa fa-close" />
                    </button>

                    <div className="product-video-card">
                        <Video url={props.product?.video ?? "https://youtube.com"} play={play} />

                        <div className="card-description">
                            {(props.product ?? {}).description}
                        </div>

                        <button className="learn-more-btn" onClick={goToSector}>
                            Voir la filière correspondante
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};