import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div id="footer">
			<div className="container">
				<div className="icons">
					<a className="icon" href="#i">
						<i className="fab fa-facebook-f" />
					</a>
					<a className="icon" href="#i">
						<i className="fab fa-twitter" />
					</a>
					<a className="icon" href="#i">
						<i className="fab fa-instagram" />
					</a>
					<a
						className="icon"
						target="_blank"
						rel="noreferrer"
						href="https://www.linkedin.com/company/nos-usines-%C3%A0-talents/about/"
					>
						<i className="fab fa-linkedin-in" />
					</a>
				</div>
				<ul className="copyright">
					<li>&copy; Nos Usines à Talents</li>
					<li>
						<a target="_blank" rel="noreferrer" href="https://matrice.io/">
							Matrice
						</a>
					</li>
					<li>
						<a target="_blank" rel="noreferrer" href="https://www.banquedesterritoires.fr/">
							Banque des Territoires
						</a>
					</li>
					<li><Link to="/terms">Politiques de confidentialité</Link></li>
				</ul>
			</div>
		</div>
	);
};

export default Footer;