import React, { Suspense, useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import * as views from "./views";
import './css/main.css';
import Loader from './components/Loader';
import Footer from './components/Footer';
import { PageProvider } from './PageContext';
import { niceSend, DEVICE_ID_KEY } from './utils';


const App = () => {
	const [bgTheme, setBgTheme] = useState("white");

	useEffect(() => {
		const createNewDeviceId = async () => {
			const DEVICE_ID = localStorage.getItem(DEVICE_ID_KEY);
			if (!DEVICE_ID) {
				const { deviceId } =
					await niceSend("/api/users", "POST");
				localStorage.setItem(DEVICE_ID_KEY, deviceId);
			}
		};

		createNewDeviceId();
	}, []);

	// Loading component management
	const [startLoad, setStartLoad] = useState(true);
	const watcherRef = useRef(null);
	useEffect(() => {
		const observer = new MutationObserver(() => {
			if (watcherRef.current.offsetParent !== null) {
				setStartLoad(false);
			}
		});
		observer.observe(watcherRef.current, {
			attributes: true,
			attributeFilter: ['style']
		});
	}, []);

	const LoadStarter = () => {
		useEffect(() => setStartLoad(true), []);
		return (
			<Loader
				bgColor="transparent"
				width="50px"
				height="50px"
				display={startLoad}
			/>
		);
	};

	return (
		<div className="app">
			<PageProvider bgTheme={bgTheme} setBgTheme={setBgTheme}>
				<BrowserRouter>
					<Navbar bgTheme={bgTheme} />
					<div className={`app-container ${bgTheme}`}>
						<Suspense fallback={<LoadStarter />}>
							<Routes>
								<Route path="/" element={<views.Home />} />
								<Route path="/sectors/:id" element={<views.Sector />} />
								<Route path="/sectors" element={<views.Sectors />} />
								<Route path="/ambassadors" element={<views.Ambassadors />} />
								<Route path="/about" element={<views.About />} />
							</Routes>
						</Suspense>
					</div>
					<div className="loadWatcher" ref={watcherRef} />
					<Footer />
				</BrowserRouter>
			</PageProvider>
		</div>
	);
};

export default App;