import React, { useState } from 'react';
import Lottie from "react-lottie";
import heartAnimation from "./lotties/like-it-20.json";

export const Heart = (props) => {
    const size = 35;
    const [isStopped, setIsStopped] = useState(true);
    const [isLiked, setIsLiked] = useState(props.isLiked);

    const stopAnimation = () => {
        if (!isStopped) {
            setIsStopped(true);
        }
    };

    const onIconClick = (e) => {
        e.stopPropagation();
        setIsStopped(isLiked);
        setIsLiked(!isLiked);
        props.onLiked();
    };
    
    const defaultOptions = {
        autoplay: true,
        animationData: heartAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    };
    const eventListeners = [
        {
            eventName: 'loopComplete',
            callback: stopAnimation
        }
    ];

    return (
        <div className="heart-icon">
            {isStopped ? (
                <i
                    className={isLiked ? "fa fa-heart" : "far fa-heart"}
                    onClick={onIconClick}
                />
            ) : <Lottie
                options={defaultOptions}
                width={size}
                height={size}
                isStopped={isStopped}
                eventListeners={eventListeners}
                className="heart-icon"
            />}
        </div>
    );
};
