import { DesktopNavbar } from "./DesktopNavbar";
import { PhoneNavbar } from "./PhoneNavbar";
import React from 'react';
import { useWindowDimensions } from '../../utils';

const links = [
    { link: "/sectors", name: "Découverte des métiers" },
    // { link: "/ambassadors", name: "Ambassadeurs" },
    // { link: "/lootbox", name: "Loterie des métiers" },
    // { link: "/quizz", name: "Quizz" },
    { link: "/about", name: "L'association" },
];

const Navbar = (props) => {
    const { width } = useWindowDimensions();
    return (
        <React.Fragment>
            {width < 600
                ? <PhoneNavbar links={links} />
                : <DesktopNavbar links={links} bgTheme={props.bgTheme} />
            }
        </React.Fragment>
    );
};

export default Navbar;