import React from 'react';
import Video from '../components/Video';

export const About = (props) => {

    // const goTo = (link) => props.history.push(link);

    return (
        <div>
            <section className="wrapper splash2" style={{ height: "75vh" }}>
                <div className="header_content">
                    <section className="banner">
                        <div className="banner_content">
                            <h2 style={{ margin: 0 }}><strong>Nos usines à talents</strong></h2>
                            <div className="about-content">
                                Une plateforme web à destination des collégiens et de leurs parents
                            </div>
                            <br />
                        </div>
                        <div className="banner_content">
                            <h2 style={{ marginBottom: "5px", fontSize: "2.5em" }}>
                                <strong>Notre objectif</strong>
                            </h2>
                            <div className="goal-cards">
                                <div className="goal-card">
                                    <p>
                                        Faire connaître les métiers industriels de façon à en améliorer l’image auprès des collégiens
                                        et de leur famille.
                                    </p>
                                </div>
                                <div className="goal-card">
                                    <p style={{ textAlign: "left" }}>
                                        Convaincre des jeunes de s’orienter vers des formations professionnelles dans l’industrie.
                                    </p>
                                </div>
                                <div className="goal-card">
                                    <p>
                                        Permettre aux industriels de proximité d’être connus des jeunes qui s’orienteront vers des filières
                                        professionnelles à proximité : mettre en valeur leurs produits, leurs expertises, leurs savoir-faire.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <section className="wrapper white">
                <div className="row_header_button">
                    <h2>La naissance du projet</h2>
                </div>
                <div className="container_horizontal">
                    <div className="bicolumn">
                        <p style={{ textAlign: "justify", textJustify: "inter-word", paddingRight: "20px" }}>
                            La Banque des Territoires et l’association Matrice se sont associées, dès juin 2019,
                            autour d’un projet d’innovation ambitieux : le Laboratoire « Territoires innovants »
                            destiné à identifier des solutions susceptibles de contribuer au développement économique
                            des « Territoires d’Industrie ». Après avoir organisé un hackathon, le projet « Nos Usines à Talents »
                            a pu voir le jour. <br /> <br />

                            La Banque des Territoires a renouvelé sa confiance dans le projet et a accepté de financer
                            plusieurs mois de développement supplémentaires.
                        </p>
                    </div>
                    <div className="bicolumn">
                        <div id="content2" className="row_text_marginleft">
                            <Video url="https://www.youtube.com/watch?v=P_EF2aI4_0g" />
                        </div>
                    </div>
                </div>
                <div className="logos">
                    <div className="logo">
                        <a target="_blank" rel="noreferrer" href="https://www.banquedesterritoires.fr/">
                            <img src="/ressources/bdt.png" alt="Logo banque des territoires" />
                        </a>
                    </div>
                    <div className="logo">
                        <a target="_blank" rel="noreferrer" href="https://matrice.io/">
                            <img src="/ressources/matrice.png" alt="Logo banque des territoires" />
                        </a>
                    </div>
                    {/* TODO: NCT logo */}
                    <div className="logo">
                        <a target="_blank" rel="noreferrer" href="https://agence-cohesion-territoires.gouv.fr/">
                            <img src="/ressources/anct.png" alt="Logo Agence nationale de cohésion des territoires" style={{height: "90px"}}/>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}