import * as cards from "../components/cards";

import React, { useState } from 'react';

import { AmbassadorWrapper } from '../components/cards';
import JobSlider from '../components/JobSlider';
import { JobVideoPopup } from '../components/cards/JobCard';
import { SectorCard } from '../components/cards/SectorCard';
import Video from '../components/Video';
import { useBackground } from '../PageContext';
import { useParams } from 'react-router-dom';
import { usePrefetch } from '../utils';

export const Sector = (props) => {
    useBackground("splash");
    const { id } = useParams();
    const [currentJob, setCurrentJob] = useState("");
    const closeJobPopup = () => setCurrentJob("");

    // TODO: Ask about this for design
    const [currentProduct, setCurrentProduct] = useState(-1);
    const closeProductPopup = () => setCurrentProduct(-1);

    const cleanApiData = (data) => {
        const basicData = data.basicData;
        delete data.basicData;
        return { ...data, ...basicData };
    };
    const [data,] = usePrefetch(`/api/sectors/${id}`, cleanApiData);

    return (
        <div>
            <section className="wrapper splash2" style={{ height: "75vh" }}>
                <div className="nuat-title">
                    Filière <strong>{data.name}</strong>
                </div>
                <div className="flex">
                    <div className="sector-card-rotated">
                        <SectorCard
                            id={data.id}
                            name={data.name}
                            image={data.image}
                            short_desc={data.short_desc}
                            nb_likes={data.nb_likes}
                            freeze
                        />
                    </div>
                    <div className="video-card" style={{ marginLeft: "15px" }}>
                        <Video url={data.video} />
                        <div className="card-description">
                            {data.description}
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper" style={{ paddingTop: "80px" }}>
                <div className="nuat-title light">
                    Les <strong>produits</strong> de cette filière
                </div>
                <div className="card-wrapper" style={{ marginTop: "50px" }}>
                    {data.products.map((item, i) => (
                        <cards.ProductCard
                            key={item.id}
                            {...item}
                            hide
                            freeze
                            moreInfo={() => setCurrentProduct(i)}
                        />
                    ))}
                </div>
            </section>

            <section className="wrapper light">
                <div className="nuat-title light">
                    Les <strong>métiers</strong> dans cette filière
                </div>
                <JobSlider data={data.jobs} updateCurrent={(id) => setCurrentJob(id)}/>
            </section>

            <JobVideoPopup show={currentJob !== ""} close={closeJobPopup} id={currentJob}/>

            <cards.ProductVideoPopup 
                show={currentProduct !== -1}
                close={closeProductPopup}
                product={data.products[currentProduct]}
            />

            <section className="wrapper splash" style={{ paddingTop: "1px" }}>
                <div className="nuat-title">
                    Les <strong>ambassadeurs</strong> de la filière
                </div>
                <AmbassadorWrapper data={data.ambassadors} />
            </section>
        </div>
    );
};
