import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import BezierEasing from "bezier-easing";
import * as icons from "../../icons";
import { useNavigate } from 'react-router-dom';
import { DEVICE_ID_KEY } from '../../utils';
import Video from '../Video';

const easing = (x) => {
    const bezierFn = BezierEasing(0.1, 1.62, 0.65, 1);
    return bezierFn(x);
};

export const SectorCard = (props) => {
    const [isHovered, setIsHovered] = useState(props.freeze || false);
    const [isLiked,] = useState(false);

    const getImagePath = () => `/ressources/sectors/${props.image}`;

    const onMouseHover = (enter) => {
        if (props.freeze) setIsHovered(true);
        else setIsHovered(enter ? true : false);
    };

    const onLiked = async () => {
        // const deviceId = localStorage.getItem(DEVICE_ID_KEY);
        // console.log(deviceId);
        // TODO
    };

    const imgStyle = useSpring({
        from: { height: "88%" },
        to: { height: "70%" },
        zIndex: -5,
        config: { duration: 150, easing, },
        reset: isHovered,
        reverse: !isHovered,
    });

    const bodyStyle = useSpring({
        from: { height: "12%" },
        to: { height: "30%" },
        reset: isHovered,
        reverse: !isHovered,
    });

    const hideStyle = useSpring({ display: isHovered ? "flex" : "none" });

    return (
        <div className="sector-card"
            style={{ cursor: props.freeze ? "auto" : "pointer" }}
            onClick={() => props.moreInfo(props.id)}
            onMouseEnter={() => onMouseHover(true)}
            onMouseLeave={() => onMouseHover()}
        >
            <animated.div className="sector-image" style={imgStyle}>
                <img className="" alt={`Secteur ${props.name}`}
                    src={getImagePath()}
                    style={{ zIndex: isHovered ? -15 : "inherit" }}
                />
            </animated.div>

            <animated.div className="card-body" style={bodyStyle} >
                <div className="card-info">
                    <h2>{props.name}</h2>
                    <animated.div style={hideStyle}>
                        <div className="left">
                            <span>{props.nb_likes}</span>
                            <icons.Heart isLiked={isLiked} onLiked={onLiked} />
                        </div>
                    </animated.div>
                </div>
                <div className="card-description">
                    {props.short_desc}
                </div>
            </animated.div>
        </div>
    );
};

export const SectorVideoPopup = (props) => {
    const [play, setPlay] = useState(true);

    const close = () => {
        setPlay(false);
        props.close();
    };

    const navigate = useNavigate();
    const goToSector = () => navigate(`/sectors/${props.sector.id}`);

    return (
        <React.Fragment>
            {props.show && (
                <div className="overlay" style={{ display: props.show ? "block" : "none" }}>
                    <div className="popup">
                        <div className="sector-card-rotated">
                            <SectorCard {...props.sector} freeze />
                        </div>

                        <div className="video-card">
                            <button className="close-btn" onClick={close}>
                                <i className="fa fa-close" />
                            </button>
                            <Video url={props.sector.video} play={play} />
                            <div className="card-description">
                                {props.sector.description}
                            </div>
                            <button className="learn-more-btn" onClick={goToSector}>
                                En savoir plus
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
