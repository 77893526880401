import React, { useState } from 'react';
import { SectorCard, SectorVideoPopup } from './SectorCard';
import { ProductCard, ProductVideoPopup } from './ProductCard';

export const CardWrapper = ({ data, showProduct, style }) => {
    const [currentItem, setCurrentItem] = useState("");

    const moreInfo = (id) => setCurrentItem(id);
    const close = () => setCurrentItem("");

    return (
        <React.Fragment>
            <div className="card-wrapper" style={style}>
                {Object.entries(data).map(([id, item]) => (
                    <React.Fragment key={id}>
                        {showProduct
                            ? <ProductCard key={id} {...item} id={id} moreInfo={moreInfo} />
                            : <SectorCard key={id} {...item} id={id} moreInfo={moreInfo} />
                        }
                    </React.Fragment>
                ))}
            </div>

            {showProduct
                ? <ProductVideoPopup
                    show={currentItem !== ""}
                    close={close}
                    product={data[currentItem]} />
                : <SectorVideoPopup
                    show={currentItem !== ""}
                    close={close}
                    sector={data[currentItem]} />
            }
        </React.Fragment>
    );
};
