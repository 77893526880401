import React from "react";
import PropTypes from "prop-types";

const Loader = (props) => {
    const {
        image,
        fullScreen,
        bgColor,
        loaderColor,
        width,
        height
    } = props;
    const { top, bottom, left, right } = loaderColor;

    const loaderStyle = {
        borderTopColor: top,
        borderBottomColor: bottom,
        borderLeftColor: left,
        borderRightColor: right,
        width,
        height
    };

    if (!props.display) {
        return null;
    }

    return (
        <div
            className={fullScreen ? "loaderArea fullscreen" : "loaderArea"}
            style={{ backgroundColor: bgColor }}
        >
            {image ? (
                image
            ) : (
                <div className="loader" style={loaderStyle}></div>
            )}
        </div>
    );
};


Loader.propTypes = {
    image: PropTypes.element,
    display: PropTypes.bool,
    fullScreen: PropTypes.bool.isRequired,
    bgColor: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    loaderColor: PropTypes.shape({
        top: PropTypes.string,
        bottom: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string
    }).isRequired
};

Loader.defaultProps = {
    image: null,
    display: true,
    fullScreen: false,
    bgColor: "rgba(0, 0, 0, 0)",
    loaderColor: {
        top: "#dc3232",
        bottom: "#dc3232",
        left: "#323037",
        right: "#323037"
    },
    width: "140px",
    height: "140px"
};
export default Loader;