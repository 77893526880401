import React from 'react';
import { AmbassadorWrapper } from '../components/cards';
import SearchBar from '../components/SearchBar';
import { useBackground } from '../PageContext';
import { usePrefetch } from "../utils";

export const Ambassadors = () => {
    useBackground("white");
    const [data,] = usePrefetch("/api/ambassadors");

    return (
        <section className="wrapper splash" style={{ height: "auto" }}>
            <div className="bars-fixed splash">
                <SearchBar />
            </div>
            <AmbassadorWrapper data={data} />
        </section>
    );
};