import React from 'react';
import { Link } from 'react-router-dom';
import { useBackground } from '../PageContext';

export const Home = () => {
    useBackground("white");
    return (
        <section className="wrapper splash" style={{ height: "65vh" }}>
            <div className="header_content">
                <section className="banner">
                    <div className="banner_content">
                        <h2><strong>L'industrie</strong></h2>
                        <p>Accessible aux jeunes</p>
                        <br />
                        <Link to="/about" className="button">Découvrir</Link>
                    </div>
                </section>
            </div>
        </section>
    );
};