import React, { createContext, useContext, useEffect } from "react";

export const PageContext = createContext({
	bgTheme: "white",
});

export const useBackground = (theme) => {
	const { setBgTheme } = useContext(PageContext);
	useEffect(() => {
		setBgTheme(theme);
		// eslint-disable-next-line
	}, []);
};

export const PageProvider = (props) => {
	const { bgTheme, setBgTheme } = props;

	return (
		<PageContext.Provider value={{ bgTheme, setBgTheme }}>
			{props.children}
		</PageContext.Provider>
	);
}